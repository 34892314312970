import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import ThemeContext from "../context/ThemeContext"

import { HeroAsteroid } from "../components/svg/index"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Footer from "../components/footer"

import loadable from "@loadable/component"
const RequestNonSSR = loadable(() => import("../components/sections/request"))

const AboutContent = styled.div`
    margin: auto auto 50px;
    max-width: 1200px;
    padding: 40px var(--container-padding) 50px;
    position: relative;

    &:after {
        background: var(--theme-coral);
        bottom: 0;
        content: "";
        height: 1px;
        position: absolute;
        width: calc(100% - 40px);

        @media (min-width: 900px) {
            display: none;
        }
    }

    @media (min-width: 900px) {
        padding: 100px var(--container-padding);
    }

    p {
        font-size: 1.8rem;
        line-height: 1.5;
    }

    .bio {
        margin: auto;
    }

    svg {
        opacity: 0.4;
        z-index: -1;
    }

    .intro {
        color: var(--theme-coral);
        font-family: var(--font-raleway-bold);
        font-size: 3rem;
    }

    .gatsby-image-wrapper {
        margin: auto auto 20px;
        width: 350px;

        @media (min-width: 900px) {
            float: left;
            margin-right: 40px;
        }
    }

    img {
        border-radius: 4px;
        margin-bottom: 0;
        position: relative;
        z-index: 1;
    }
`

const IndexPage = ({ location }) => {
    const data = useStaticQuery(graphql`
        query {
            aboutJson {
                image {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Rafael Heard - About" />
            <ThemeContext.Consumer>
                {theme => (
                    <>
                        <Hero theme={theme} location={location} />
                        <AboutContent>
                         
                            <div className="bio">
                                <HeroAsteroid />
                                <p>
                                    <span className="intro">
                                        Hi! I'm Rafael.{" "}
                                    </span>
                                    Professionally, I am a Front End Engineer.
                                    On the evenings and on weekends, I spend my
                                    time working to tame and beautify my
                                    backyard, which is then my sanctuary to
                                   listen to podcasts, enjoy some tea



, or play a game of basketball with my
                                    son. But let's be honest -- you're here to
                                    learn about my how my skills can help you
                                    bring your project to life!
                                </p>
                                <p>
                                    I am experienced and have worked with
                                    various companies from large coffee and
                                    concierge providers, local in town
                                    businesses (i.e. restaurant, barber shops,
                                    real estate brokers) to small start ups.
                                    These varying exerperiences have helped me
                                    to gin valuable experience to work with a
                                    multitude of clients. Equal to my experience
                                    is my passion for my craft. I enjoy the
                                    whole process - mocking designs to
                                    developing the web and software aspects to
                                    bring a project to life.{" "}
                                </p>
                                <p>
                                    I feel called to work with smaller companies
                                    to help them reach their audiences through
                                    the web without breaking the bank! I am
                                    comfortable working with pre-existing
                                    websites/applications or starting from
                                    scratch to create a custom project. I look
                                    forward to hearing from you, so that I can
                                    answer any questions or put together a quote
                                    for you!
                                </p>
                            </div>
                        </AboutContent>

                        <RequestNonSSR theme={theme} />
                        <Footer theme={theme} />
                    </>
                )}
            </ThemeContext.Consumer>
        </Layout>
    )
}

export default IndexPage
